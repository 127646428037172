import { useEffect } from 'react';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import assets from '@dt/graphql-support/horizon/assets';
import { downloadCsv } from '@utils/downloadCsv';
import { dateFormats } from '../../../util/dateFormats';
import { formatDateDefault } from '../../../util/formatDateDefault';

function convertToCSV(objArray) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line != '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  downloadCsv({
    data: csv,
    fileName: fileTitle,
  });
}

export default function useExportAssets(filters) {
  const [fetchAssets, { data, fetchMore, loading, error }] = useLazyQuery(assets.list);

  const caller = () => {
    fetchAssets({
      variables: {
        ...filters,
        page_size: 250,
      },
    });
  };

  // Fetch all assets.
  useEffect(() => {
    if (fetchMore) {
      fetchMore && fetchMore();
    }
  }, [fetchMore]);

  useEffect(() => {
    if (!fetchMore && data) {
      exportCSVFile(
        {
          asset_type: 'Asset Type'.replace(/,/g, ''),
          date_created: 'Discovery Date'.replace(/,/g, ''),

          discovered_via: 'Discovered Via'.replace(/,/g, ''),

          hosted_on: 'Hosted On'.replace(/,/g, ''),
          // remove commas to avoid errors
          name: 'Asset Name'.replace(/,/g, ''),
          open_important_policy_violations_count: 'Open Important Policy Violations Count'.replace(/,/g, ''),
          open_proactive_policy_violations_count: 'Open Proactive Policy Violations Count'.replace(/,/g, ''),
          open_urgent_policy_violations_count: 'Open Urgent Policy Violations Count'.replace(/,/g, ''),
          status: 'Status',
          url: 'Asset Url'.replace(/,/g, ''),
        },
        data.asset_list.assets.map(asset => ({
          asset_type: asset.asset_type_name.replace(/,/g, ''),
          date_created: formatDateDefault({ date: asset.date_created, formatStr: dateFormats.MMddyyyy }),
          discovered_via: asset.discovered_via.replace(/,/g, ''),
          hosted_on: (asset.hosted_on || ' ').replace(/,/g, '') || '',
          name: asset.name.replace(/,/g, ''),
          open_important_policy_violations_count: asset.open_important_policy_violations_count,
          open_proactive_policy_violations_count: asset.open_proactive_policy_violations_count,
          open_urgent_policy_violations_count: asset.open_urgent_policy_violations_count,
          status: asset.status.replace(/,/g, ''),
          url: (asset.url || ' ').replace(/,/g, ''),
        })),
        `data_theorem_assets_${formatDateDefault({
          date: Date.now(),
          formatStr: dateFormats.MMddyyyy,
        })}_${Object.values({
          ...filters,
        })
          .join('_')
          .replace(',', '_')
          .toLowerCase()}`,
      );
    }
  }, [fetchMore, data, filters]);

  return {
    caller,
    completedCount: data?.asset_list.assets.length || 0,
    error,
    loading,
    totalCount: data?.asset_list.pagination_information.total_count || 0,
  };
}
