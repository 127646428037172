import { Box, Tooltip, styled } from '@mui/material';
import React from 'react';
// @ts-expect-error ignore js file
import Label from '@dt/material-components/Label';
import { palette } from '@dt/theme';
const TagContainer = styled('div')(({ width, small, verticalAdjustment, rounded, backgroundColor, color, maxWidth }) => ({
    '&&': {
        alignItems: 'center',
        backgroundColor: typeof backgroundColor === 'string' ? backgroundColor : palette.gray45,
        borderRadius: rounded ? 16 : 4,
        color: typeof color === 'string' ? color : palette.gray20,
        display: 'inline-flex',
        fontSize: small ? 12 : 14,
        height: '1.8em',
        marginTop: verticalAdjustment || 'initial',
        maxWidth: maxWidth,
        padding: small ? '2px 4px' : '2px 8px',
        width: width || 'fit-content',
    },
}));
const TagLabel = styled('span')(({ icon }) => ({
    '&&': { marginLeft: icon ? 4 : 0, maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
}));
const TagIcon = styled('div')(({ icon }) => ({
    '&&': {
        '& img, & svg': {
            maxHeight: '100%',
        },
        alignItems: 'center',
        display: 'flex',
        height: icon ? '100%' : 14,
    },
}));
const Tag = ({ icon, small, label, backgroundColor, color, tooltipText, rounded = false, maxWidth, verticalAdjustment, noPadding, width, }) => {
    return (React.createElement(Tooltip, { title: tooltipText ?? label },
        React.createElement(Box, { component: "span", display: "inline-flex", whiteSpace: "nowrap" },
            React.createElement(Label, { noPadding: noPadding, rounded: rounded, variant: "transparent" },
                React.createElement(TagContainer, { backgroundColor: backgroundColor, color: color, maxWidth: maxWidth, rounded: rounded, small: small, verticalAdjustment: verticalAdjustment, width: width },
                    React.createElement(TagIcon, { icon: icon }, icon ?? ''),
                    React.createElement(TagLabel, { icon: icon }, label))))));
};
export default Tag;
